interface UIState {
  drawerOpen: boolean;
}

export default {
  namespaced: true,
  state(): UIState {
    return {
      drawerOpen: true,
    };
  },
  mutations: {
    closeDrawer(state: UIState): void {
      console.log("closing...");	    
      state.drawerOpen = false;
    },
    openDrawer(state: UIState): void {
      state.drawerOpen = true;
    },
  },
  actions: {

  },
  getters: {

  },
};
