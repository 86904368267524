<template>
  <div class="mdc-dialog" ref="settingsDialogElem">
    <div class="mdc-dialog__container">
      <div class="mdc-dialog__surface"
           role="alertdialog"
           aria-modal="true"
           aria-labelledby="Settings"
           aria-describedby="my-dialog-content">
        <h1 class="mdc-dialog__title">Settings</h1>
        <div class="mdc-dialog__content">
          language<br>theme<br>etc
        </div>
      </div>
    </div>
    <div class="mdc-dialog__scrim" />
  </div>

  <header ref="topAppBarElem" class="mdc-top-app-bar">
    <div class="mdc-top-app-bar__row">
      <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
        <Icon :icon="icons.menu" class=" mdc-top-app-bar__navigation-icon mdc-icon-button" />
      </section>
      <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end" role="toolbar">
        <button @click="openSettings()" class="mdc-top-app-bar__action-item mdc-icon-button" aria-label="Settings"><Icon :icon="icons.settings" /></button>
    </section>
    </div>
  </header>

  <template v-if="mobile">
    <aside ref="modalDrawerElem" class="mdc-drawer mdc-drawer--modal mdc-top-app-bar--fixed-adjust">
      <div class="mdc-drawer__header">
        <h3 class="mdc-drawer__title">mobile: {{ mobile }}</h3>
        <h2 class="mdc-drawer__subtitle">user: {{ JSON.stringify(user) }}</h2>
      </div>
      <div class="mdc-drawer__content">
        <nav class="mdc-list">
          <template v-for="item in drawerItems" :key="item.to">
          <router-link :to="item.to" class="mdc-list-item" active-class="mdc-list-item--activated">
            <span class="mdc-list-item__ripple" />
            <Icon
              :icon="icons[item.icon]"
              class="mdc-list-item__graphic"
              aria-hidden="true"
            />
            <span class="mdc-list-item__text" v-text="item.text" />
          </router-link>
          </template>
        </nav>
      </div>
    </aside>
    <div class="mdc-drawer-scrim" />
  </template>

  <aside v-else ref="dismissibleDrawerElem" class="mdc-drawer mdc-drawer--dismissible mdc-top-app-bar--fixed-adjust">
    <div class="mdc-drawer__header">
      <h3 class="mdc-drawer__title">mobile: {{ mobile }}</h3>
      <h2 class="mdc-drawer__subtitle">user: {{ JSON.stringify(user) }}</h2>
    </div>
    <div class="mdc-drawer__content">
      <nav class="mdc-list">
        <template v-for="(item, index) in drawerItems" :key="index">
        <router-link :to="item.to" class="mdc-list-item" active-class="mdc-list-item--activated" aria-current="page">
          <span class="mdc-list-item__ripple" />
          <Icon :icon="icons[item.icon]" class="mdc-list-item__graphic" aria-hidden="true" />
          <span class="mdc-list-item__text" v-text="item.text" />
        </router-link> 
        </template>
      </nav>
    </div>
  </aside>

  <main class="mdc-drawer-app-content mdc-top-app-bar--fixed-adjust">
    <router-view />
  </main>
</template>

<script lang="ts">
import {Options, mixins} from "vue-class-component";

import {Icon} from "@iconify/vue";

import {MDCDialog} from "@material/dialog";
import {MDCDrawer} from "@material/drawer";
import {MDCTopAppBar} from "@material/top-app-bar";

import type {User} from "@/auth";
import {StoreState} from "@/utils";
import Icons from "@/mixins/Icons";
import Mobile from "@/mixins/Mobile";


const watchMobile = function(this: App): void {
  this.$nextTick(this.attachDrawers);
};

@Options({
  components: {Icon},
  watch: {
    mobile: watchMobile,
  },
})
export default class App extends mixins(Icons, Mobile) {
  declare $refs: {
    dismissibleDrawerElem?: HTMLElement,
    modalDrawerElem?: HTMLElement,
    settingsDialogElem: HTMLElement,
    topAppBarElem: HTMLElement,
  };

  dismissibleDrawer: MDCDrawer | null = null;
  modalDrawer: MDCDrawer | null = null;
  settingsDialog: MDCDialog | null = null;

  drawerItems = [
    {text: "Home", icon: "home", to: {name: "home"}},
    {text: "CV", icon: "description", to: {name: "cv"}},
    {text: "Photos", icon: "photoLibrary", to: {name: "photos"}},
    {text: "Piano", icon: "piano", to: {name: "piano"}},
  ];

  @StoreState("auth") user!: User | null;
  @StoreState("ui") drawerOpen!: boolean;

  attachDrawers(): void {
    if (!this.dismissibleDrawer && this.$refs.dismissibleDrawerElem) {
      this.dismissibleDrawer = MDCDrawer.attachTo(this.$refs.dismissibleDrawerElem);
      this.dismissibleDrawer.open = this.drawerOpen;
    } else if (!this.$refs.dismissibleDrawerElem) {
      this.dismissibleDrawer = null;
    }

    if (!this.modalDrawer && this.$refs.modalDrawerElem)
      this.modalDrawer = MDCDrawer.attachTo(this.$refs.modalDrawerElem);
    else if (!this.$refs.modalDrawerElem)
      this.modalDrawer = null;
  }

  created(): void {
    this.loadIcons("ic", [
      "description",
      "home",
      "menu",
      "piano",
      "settings",
    ]);
  }

  mounted(): void {
    this.attachDrawers();
    this.settingsDialog = MDCDialog.attachTo(this.$refs.settingsDialogElem);
    const topAppBar = MDCTopAppBar.attachTo(this.$refs.topAppBarElem);

    topAppBar.listen("MDCTopAppBar:nav", () => {
      if (this.mobile) {
        if (this.modalDrawer)
          this.modalDrawer.open = !this.modalDrawer.open;
      } else if (this.dismissibleDrawer) {
        if (this.dismissibleDrawer.open) {
          this.dismissibleDrawer.open = false;
          this.$store.commit("ui/closeDrawer");
        } else {
          this.dismissibleDrawer.open = true;
          this.$store.commit("ui/openDrawer");
        }
      }
    });
  }

  openSettings(): void {
    this.settingsDialog?.open();
  }
}
</script>

<style lang="scss">
@use "@material/button/styles";
@use "@material/dialog";
@use "@material/drawer";
@use "@material/icon-button";
@use "@material/list";
@use "@material/top-app-bar/mdc-top-app-bar";

@include dialog.core-styles;
@include drawer.core-styles;
@include drawer.dismissible-core-styles;
@include drawer.modal-core-styles;
@include icon-button.core-styles;
@include list.core-styles;

@import "modern-normalize";

$dot-width: 30px;
$dot-height: $dot-width;
@import "three-dots/sass/three-dots";

body {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.three-dots {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app {
  display: flex;
  width: 100vw;
  flex: 1;
}
</style>

<style lang="scss">
main {
  overflow: auto;
  height: 100%;
}

.mdc-top-app-bar {
  z-index: 7;
}
</style>
