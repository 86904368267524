import {createDecorator} from "vue-class-component";
import type {VueDecorator} from "vue-class-component";


export const nnbsp = "\u202F";  // narrow no-break space


export const StoreState = function(module?: string, name?: string): VueDecorator {
  return createDecorator((options, key) => {
    if (!options.computed)
      options.computed = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options.computed[key] = function(): any {
      const state = module ? this.$store.state[module] : this.$store.state;
      return state[name ?? key];
    };
  });
}
