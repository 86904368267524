import {createStore} from "vuex";
import VuexPersist from "vuex-persist";

import auth from "./auth";
import ui from "./ui";


const vuexPersist = new VuexPersist();

export default createStore({
  modules: {
    auth,
    ui,
  },
  plugins: [vuexPersist.plugin],
})
