/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {createRouter, createWebHistory} from "vue-router";
import type {RouteRecordRaw} from "vue-router";

import config from "@/config";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/cv",
    name: "cv",
    component: () => import("@/views/CV.vue"),
  },
  {
    path: "/photos",
    name: "photos",
    component: () => import(/* webpackChunkName: "photos" */ "@/views/Home.vue"),
  },
  {
    path: "/piano",
    name: "piano",
    component: () => import(/* webpackChunkName: "piano" */ "@/views/Home.vue"),
  },
]

export default createRouter({
  history: createWebHistory(config.baseURL),
  routes,
});
