
import {Options, mixins} from "vue-class-component";

import {Icon} from "@iconify/vue";

import {MDCDialog} from "@material/dialog";
import {MDCDrawer} from "@material/drawer";
import {MDCTopAppBar} from "@material/top-app-bar";

import type {User} from "@/auth";
import {StoreState} from "@/utils";
import Icons from "@/mixins/Icons";
import Mobile from "@/mixins/Mobile";


const watchMobile = function(this: App): void {
  this.$nextTick(this.attachDrawers);
};

@Options({
  components: {Icon},
  watch: {
    mobile: watchMobile,
  },
})
export default class App extends mixins(Icons, Mobile) {
  declare $refs: {
    dismissibleDrawerElem?: HTMLElement,
    modalDrawerElem?: HTMLElement,
    settingsDialogElem: HTMLElement,
    topAppBarElem: HTMLElement,
  };

  dismissibleDrawer: MDCDrawer | null = null;
  modalDrawer: MDCDrawer | null = null;
  settingsDialog: MDCDialog | null = null;

  drawerItems = [
    {text: "Home", icon: "home", to: {name: "home"}},
    {text: "CV", icon: "description", to: {name: "cv"}},
    {text: "Photos", icon: "photoLibrary", to: {name: "photos"}},
    {text: "Piano", icon: "piano", to: {name: "piano"}},
  ];

  @StoreState("auth") user!: User | null;
  @StoreState("ui") drawerOpen!: boolean;

  attachDrawers(): void {
    if (!this.dismissibleDrawer && this.$refs.dismissibleDrawerElem) {
      this.dismissibleDrawer = MDCDrawer.attachTo(this.$refs.dismissibleDrawerElem);
      this.dismissibleDrawer.open = this.drawerOpen;
    } else if (!this.$refs.dismissibleDrawerElem) {
      this.dismissibleDrawer = null;
    }

    if (!this.modalDrawer && this.$refs.modalDrawerElem)
      this.modalDrawer = MDCDrawer.attachTo(this.$refs.modalDrawerElem);
    else if (!this.$refs.modalDrawerElem)
      this.modalDrawer = null;
  }

  created(): void {
    this.loadIcons("ic", [
      "description",
      "home",
      "menu",
      "piano",
      "settings",
    ]);
  }

  mounted(): void {
    this.attachDrawers();
    this.settingsDialog = MDCDialog.attachTo(this.$refs.settingsDialogElem);
    const topAppBar = MDCTopAppBar.attachTo(this.$refs.topAppBarElem);

    topAppBar.listen("MDCTopAppBar:nav", () => {
      if (this.mobile) {
        if (this.modalDrawer)
          this.modalDrawer.open = !this.modalDrawer.open;
      } else if (this.dismissibleDrawer) {
        if (this.dismissibleDrawer.open) {
          this.dismissibleDrawer.open = false;
          this.$store.commit("ui/closeDrawer");
        } else {
          this.dismissibleDrawer.open = true;
          this.$store.commit("ui/openDrawer");
        }
      }
    });
  }

  openSettings(): void {
    this.settingsDialog?.open();
  }
}
