import {Vue} from "vue-class-component";


const mediaQuery = matchMedia("(max-width: 768px)");

export default class Mobile extends Vue {
  public mobile = mediaQuery.matches;

  public created(): void {
    mediaQuery.addEventListener("change", event => {
      this.mobile = event.matches;
    });
  }
}
