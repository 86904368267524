import {IconifyIcon} from "@iconify/vue";
import {Vue} from "vue-class-component";


export default class Mobile extends Vue {
  icons: Record<string, IconifyIcon> = {};

  async loadIcons(set: string, names: string[]): Promise<void> {
    for (const name of names) {
      const module = await import(/* webpackIgnore: true */ `@/../node_modules/@iconify/icons-${set}/${name}`);
      this.icons[name] = module.default;
    }
  }
}
