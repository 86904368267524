import type {User} from "@/auth";


interface AuthState {
  user: User | null;
}

export default {
  namespaced: true,
  state(): AuthState {
    return {
      user: null,
    };
  },
  mutations: {
    login(state: AuthState, data: User): void {
      state.user = data;
    },
    logout(state: AuthState): void {
      state.user = null;
    }
  },
  actions: {

  },
  getters: {

  },
};
